import React, {useContext, useState} from "react";
import {AppContext} from "../../App";
import {useStyles} from "./routeStyle";
import moment from "moment";


function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export default ({route}) => {
  const classes = useStyles();

  const {appState, appDispatch} = useContext(AppContext);

  const tryLimit = appState.section.tryLimit;

  const [value, setValue] = useState('');
  const [state, setState] = useState('select');
  const [holdNumber, setHoldNumber] = useState(null);
  const [reachUp, setReachUp] = useState(false);

  const participation = appState.participations[parseInt(value)];
  const name = (participation ? participation.name : '');
  const tries = (participation ? (participation.tries[route.id] || 0) : null);

  const toVerify = (hn, reachUp) => {
    setHoldNumber(hn);
    setReachUp(reachUp);
    setState('verify');
  };

  const commit = () => {

    if (participation === undefined || holdNumber === null) {
      alert('Programhiba, kérlek szólj.');
      return;
    }

    const climb = {
      uuid: uuidv4(),
      section: appState.section.id,
      participation: participation.id,
      route: route.id,
      startTime: moment(),
      endTime: moment(),
      holdNumber: holdNumber,
      reachUp: reachUp
    };

    appDispatch({
      action: 'climb-upload',
      data: climb
    });

    const tries = participation.tries[route.id];
    participation.tries[route.id] = tries ? tries + 1 : 1;

    appDispatch({
      action: 'participant-data',
      data: Object.assign({}, participation)
    });

    setValue('');
    setHoldNumber(null);
    setReachUp(false);
    setState('select');
  };

  switch (state) {

    case 'select' :
      return (
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "flex-start", alignItems: 'center'}}>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, alignItems: 'center'}}>
              <Route route={route}/>

              <div className={classes.simpleTitle}>Rajtszám:</div>
              <input
                  className={classes.simpleInput}
                  autoFocus
                  value={value}
                  type="number"
                  name="startNumber"
                  onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "flex-start", alignItems: 'center'}}>
              <div className={classes.addName}>{name}</div>
              <Tries tries={tries} tryLimit={tryLimit}/>
              {participation === undefined ? null :
                  <div>
                    <div className={classes.resultButton} onClick={() => setState('enter')}>tovább</div>
                  </div>
              }
            </div>
          </div>
      );

    case 'enter':
      return (
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "flex-start", alignItems: 'center'}}>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, alignItems: 'center'}}>
              <Route route={route}/>
              <div className={classes.resultStartNumber}>{participation.startNumber}</div>
            </div>
            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "flex-start", alignItems: 'center'}}>
              <div className={classes.resultName}>{participation.name}</div>
            </div>
            <div>
              <div className={classes.resultButtonList}>
                <input
                    className={classes.addInput}
                    autoFocus
                    value={holdNumber}
                    type="number"
                    name="holdNumber"
                    onChange={(e) => setHoldNumber(e.target.value)}
                    onKeyDown={(e) => {
                      if (isNaN(parseInt(holdNumber))) return;
                      if (e.keyCode === 13) toVerify(holdNumber, reachUp);
                    }}
                />
                <div className={classes.reachUp}>{reachUp ? "+" : ""}</div>
                <div className={classes.resultButton} onClick={() => setReachUp(!reachUp)}>felnyúlás</div>
                <div
                    className={isNaN(parseInt(holdNumber)) ? classes.inactiveResultButton : classes.resultButton}
                    onClick={() => {
                      if (isNaN(parseInt(holdNumber))) return;
                      toVerify(holdNumber, reachUp);
                    }}
                >
                  rendben
                </div>
              </div>
              <div>
                <div className={classes.resultCancelButton} style={{marginTop: "12px"}}
                     onClick={() => {
                       setState('select');
                       setValue('');
                       setHoldNumber(null);
                     }}>vissza
                </div>
              </div>
            </div>
          </div>
      );

    case 'verify' :
      return (
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "flex-start", alignItems: 'center'}}>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, alignItems: 'center'}}>
              <Route route={route}/>
              <div className={classes.resultStartNumber}>{participation.startNumber}</div>
            </div>
            <div style={{display: "flex", flexDirection: "column", flexGrow: 0, justifyContent: "flex-start", alignItems: 'center'}}>
              <div className={classes.resultName}>{participation.name}</div>
            </div>
            <div>
              <div className={classes.verifyResult}>{holdNumber}</div>
              <div className={classes.reachUp}>{reachUp ? "+" : ""}</div>
            </div>
            <div>
              <div className={classes.resultButtonList}>
                <div className={classes.verifyCancelButton} onClick={() => setState('enter')}>vissza</div>
                <div className={classes.resultButton} onClick={commit}>mentés</div>
              </div>
            </div>
          </div>
      );

    default:
      alert('Programhiba, kérlek szólj.')
  }

};

const Route = ({route}) => {
  const classes = useStyles();

  return (
      <div className={classes.simpleRouteLabel}>
        <div className={classes.label}>
          {route.label}
          <div className={classes.color} style={{backgroundColor: route.color}}>&nbsp;</div>
        </div>
      </div>
  );
};

const Tries = ({tries, tryLimit}) => {
  const classes = useStyles();

  if (tries == null) return null;

  if (tries >= tryLimit) {
    return <div className={classes.simpleTries} style={{color: "red"}}> nincs több próba</div>
  } else {
    return <div className={classes.simpleTries}>{tryLimit - tries} próba van még</div>
  }
};