import React, {useContext} from "react";
import {AppContext} from "../../App";
import RouteMain from "./RouteMain";
import RouteSelector from "./RouteSelector";
import SimpleBoulder from "./SimpleBoulder";
import SimpleLead from "./SimpleLead";

export default ({index}) => {
  const {appState} = useContext(AppContext);

  const route = appState.routes[index];

  if (appState.started) {
    const options = appState.section.options;
    if (options !== undefined && options.indexOf('simple-ref') !== -1) {
      if (appState.section.climbingType === 'BOULDER') {
        return route ? <SimpleBoulder route={route}/> : null
      } else {
        return route ? <SimpleLead route={route}/> : null
      }
    } else {
      return route ? <RouteMain index={index}/> : null
    }
  } else {
    return <RouteSelector index={index}/>
  }
}