import React, {useContext, useState} from "react";
import {useStyles} from "./routeStyle";
import {AppContext} from "../../App";

export default ({route, index, setMode}) => {
  const classes = useStyles();
  const {appState, appDispatch} = useContext(AppContext);

  const p = appState.participations[route.queue[0]];

  const back = () => {
    route.progress.endTime = undefined;

    appDispatch({
      action: 'route-data',
      index: index,
      data: Object.assign({}, route)
    });

    setMode('normal');
  };

  const result = (holdNumber, reachUp) => {
    route.progress.holdNumber = holdNumber;
    route.progress.reachUp = reachUp;

    appDispatch({
      action: 'route-data',
      index: index,
      data: Object.assign({}, route)
    });

    setMode('verify');
  };

  return (
      <div>
        <div>
          <div className={classes.resultStartNumber}>{p.startNumber}</div>
          <div className={classes.resultName}>{p.name}</div>
        </div>
        <div>
          <ResultValue handler={result} classes={classes} route={route}/>
          <div>
            <div className={classes.resultCancelButton} onClick={back}>vissza</div>
          </div>
        </div>
      </div>
  );
};

const ResultValue = ({classes, handler, route}) => {
  const [holdNumber, setHoldNumber] = useState('');
  const [reachUp, setReachUp] = useState(false);

  if (route.climbingType === 'LEAD') {
    return (
        <div className={classes.resultButtonList}>
          <input
              className={classes.addInput}
              autoFocus
              value={holdNumber}
              type="number"
              name="holdNumber"
              onChange={(e) => {setHoldNumber(e.target.value);}}
              onKeyDown={(e) => {
                if (isNaN(parseInt(holdNumber))) return;
                if (e.keyCode === 13) handler(holdNumber)
              }}
          />
          <div className={classes.reachUp}>{reachUp ? "+" : ""}</div>
          <div className={classes.resultButton} onClick={() => setReachUp(!reachUp)}>felnyúlás</div>
          <div
              className={isNaN(parseInt(holdNumber)) ? classes.inactiveResultButton : classes.resultButton}
              onClick={() => {
                if (isNaN(parseInt(holdNumber))) return;
                handler(holdNumber, reachUp);
              }}
          >
            rendben
          </div>
        </div>
    );
  } else {
    return (
        <div className={classes.resultButtonList}>
          <div className={classes.resultButton} onClick={() => handler(0, false)}>próba</div>
          <div className={classes.resultButton} onClick={() => handler(1, false)}>zóna</div>
          <div className={classes.resultButton} onClick={() => handler(2, false)}>top</div>
        </div>
    );
  }
};