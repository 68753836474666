import React, {useContext} from "react";
import {useStyles} from "./routeStyle";
import {AppContext} from "../../App";

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export default ({route, index, setMode, setSelected}) => {
  const classes = useStyles();
  const {appState, appDispatch} = useContext(AppContext);

  const p = appState.participations[route.queue[0]];

  const back = () => {
    route.progress.holdNumber = undefined;

    appDispatch({
      action: 'route-data',
      index: index,
      data: Object.assign({}, route)
    });

    setMode('entry');
  };

  const commit = () => {
    const progress = route.progress;

    const climb = {
      uuid: uuidv4(),
      section: appState.section.id,
      participation: appState.participations[progress.startNumber].id,
      route: route.id,
      startTime: progress.startTime,
      endTime: progress.endTime,
      holdNumber: progress.holdNumber,
      reachUp: progress.reachUp
    };

    appDispatch({
      action: 'climb-upload',
      data: climb
    });

    route.progress = null;
    route.queue.shift();

    appDispatch({
      action: 'route-data',
      index: index,
      data: Object.assign({}, route)
    });

    const tries = p.tries[route.id]
    p.tries[route.id] = tries ? tries + 1 : 1

    appDispatch({
      action: 'participant-data',
      data: Object.assign({}, p)
    });

    setMode('normal');
    setSelected(null);
  };

  let result = 'Hiba, kérlek szólj a supportnak';
  if (route.climbingType === 'BOULDER') {
    if (route.progress.holdNumber === 0) {
      result = 'próba'
    }
    if (route.progress.holdNumber === 1) {
      result = 'zóna';
    }
    if (route.progress.holdNumber === 2) {
      result = 'top';
    }
  } else {
    result = route.progress.holdNumber + (route.progress.reachUp ? "+" : "");
  }

  return (
      <div>
        <div>
          <div className={classes.resultStartNumber}>{p.startNumber}</div>
          <div className={classes.resultName}>{p.name}</div>
        </div>
        <div>
          <div className={classes.verifyResult}>{result}</div>
        </div>
        <div>
          <div className={classes.resultButtonList}>
            <div className={classes.verifyCancelButton} onClick={back}>vissza</div>
            <div className={classes.resultButton} onClick={commit}>mentés</div>
          </div>
        </div>
      </div>
  );
};