import React, {useContext, useState} from "react";
import {AppContext} from "../../App";
import {useStyles} from "./routeStyle";
import RouteQueue from "./RouteQueue";
import RouteState from "./RouteState";
import Participation from "./Participation";
import ResultEntry from "./ResultEntry";
import ResultVerify from "./ResultVerify";

const initialState = (route) => {
  if (route.progress === null) return 'normal';
  if (route.progress.endTime === undefined) return 'normal';
  if (route.progress.holdNumber === undefined) return 'entry';
  return 'verify';
};

export default ({index}) => {
  const {appState} = useContext(AppContext);
  const classes = useStyles();

  const route = appState.routes[index];

  const [mode, setMode] = useState(initialState(route));
  const [selected, setSelected] = useState(null);

  switch (mode) {
    case 'normal' :
      return (
          <div className={classes.routeContainer}>
            <RouteQueue route={route} index={index} setMode={setMode} selected={selected} setSelected={setSelected}/>
            <RouteState route={route} index={index} selected={selected} mode={mode}  setMode={setMode}/>
          </div>
      );
    case 'participant' :
      return (
          <div className={classes.routeContainer}>
            <Participation route={route} index={index} setMode={setMode}/>
            <RouteState route={route} index={index} selected={selected} mode={mode} setMode={setMode} />
          </div>
      );
    case 'entry' :
      return (
          <div className={classes.routeContainer}>
            <ResultEntry route={route} index={index} setMode={setMode}/>
            <RouteState route={route} index={index} selected={selected} mode={mode} setMode={setMode} />
          </div>
      );
    case 'verify' :
      return (
          <div className={classes.routeContainer}>
            <ResultVerify route={route} index={index} setMode={setMode} setSelected={setSelected}/>
            <RouteState route={route} index={index} selected={selected} mode={mode} setMode={setMode} />
          </div>
      );
    default :
      return 'Hiba, kérlek szólj a supportnak.'
  }
};