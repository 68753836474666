import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {get} from "./Lib/session";
import SessionData from "./Lib/SessionData";
import {Record} from "immutable";

const AppStateRecord = Record({
  started: false,
  section: null,
  participations: {  },
  uploads: [  ],
  routes: [null, null, null, null]
});

const parse = (key, def) => {
  const s = localStorage.getItem(key);
  if (s == null) return def;
  return JSON.parse(s);
};

const initialState = {
  started: parse('ref-started', false),
  section: parse('ref-section', null),
  participations: {  },
  routes: [
    parse('ref-route-0', null),
    parse('ref-route-1', null),
    parse('ref-route-2', null),
    parse('ref-route-3', null)
  ]
};


if (initialState.section != null) {
  initialState.section.participations.forEach(p => {
    initialState.participations[p.startNumber] = p;
  });
}

const render = (sessionData) => {
  ReactDOM.render(<App sessionData={sessionData} initialState={AppStateRecord(initialState)}/>, document.getElementById('root'))
};

get(
    sessionData => render(sessionData),
    () => render(new SessionData(), AppStateRecord())
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
