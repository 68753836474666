import {createUseStyles} from 'react-jss'
import baseStyle from "../../baseStyle"

const genericButton = {
  borderRadius: 4,
  padding: "8px 24px 8px 24px",
  margin: "4px 8px 4px 8px",
  cursor: 'pointer',
  textTransform: 'lowercase',
  fontSize: 18,
  fontWeight: 500,
  color: "white",
};

const routeStyle = {
  ...baseStyle,

  button: {
    ...genericButton,
    backgroundColor: "#388e3c",
    color: "white",
  },
  routeContainer: {
    flexGrow: "1",
    display: "flex",
    flexDirection: "row"
  },
  label: {
    fontSize: 28,
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyItems: "space-around",
    margin: "16px 0px 16px 20px"
  },
  color: {
    marginLeft: 8,
    marginRight: 8,
    minWidth: 20,
    width: 20,
    height: 20,
    border: "1px gray solid",
    display: "inline-block",
  },
  colorSelected: {
    marginLeft: 4,
    marginRight: 8,
    minWidth: 20,
    width: 20,
    height: 20,
    border: "1px white solid",
    display: "inline-block",
  },
  routeList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },

  simpleRouteLabel : {
    //display: "inline-block"
    whiteSpace: "nowrap"
  },
  simpleTries : {
    marginTop: 16,
    marginLeft: 8,
    marginBottom: 24,
    fontSize: 24,
    fontWeight: 500,
  },
  simpleTitle: {
    display: "inline-block",
    fontSize: 18,
    fontWeight: 500,
    marginLeft: 24
  },
  simpleInput: {
    fontSize: 18,
    fontWeight: 500,
    height: "2em",
    width: "4em",
    marginLeft: 8,
    textAlign: "center"
  },

  // route selector

  routeSelectorContainer: {
    display: "flex",
    flexDirection: "column",
    width: "25%",
    alignItems: "middle",
    overflow: "auto"
  },
  routeListEntry: {
    marginLeft: 10,
    padding: 4,
    height: 40,
    maxHeight: 40,
    fontWeight: 500,
    fontSize: 28,
    whiteSpace: 'nowrap'
  },
  selected: {
    backgroundColor: "#396593",
    borderRadius: 2,
    color: "white"
  },
  routeListFirst: {
    borderTop: "1px solid green",
    paddingTop: 16,
  },
  routeListSeparator: {
    paddingBottom: 16,
    marginBottom: 16,
    borderBottom: "1px solid green"
  },

  // participation

  addTitle: {
    display: "inline-block",
    marginTop: 8,
    marginLeft: 8,
    fontSize: 18,
    fontWeight: 500,
  },
  addInput: {
    fontSize: 18,
    fontWeight: 500,
    height: "2em",
    width: "4em",
    marginLeft: 8,
    marginTop: 20,
    textAlign: "center"
  },
  addName: {
    marginTop: 24,
    marginLeft: 8,
    fontSize: 24,
    fontWeight: 500,
    color: "teal"
  },
  addTries: {
    marginTop: 16,
    marginLeft: 8,
    fontSize: 24,
    fontWeight: 500,
  },

  // queue

  queueHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: "47px",
    height: "47px",
    maxHeight: "47px"
  },
  queueTableContainer: {
    minHeight: "calc(100% - 47px)",
    height: "calc(100% - 47px)",
    maxHeight: "calc(100% - 47px)",
    paddingTop: 8,
    overflowY: "auto"
  },
  queueTable: {
    width: "calc(100% - 16px)",
    fontSize: 18,
    fontWeight: 500,
    cellSpacing: 0,
    cellPadding: 0,
    borderCollapse: "collapse",
    margin: "0px 8px 0px 8px",
  },
  queueStartNumber: {
    width: "3em",
    maxWidth: "3em",
    borderBottom: "1px solid #388e3c"
  },
  queueName: {
    textAlign: "left",
    padding: "8px 0px 8px 0px",
    margin: "4px 0px 4px 0px",
    borderBottom: "1px solid #388e3c"
  },
  queueStatus: {
    width: "6em",
    maxWidth: "6em",
    borderBottom: "1px solid #388e3c"
  },
  queueStatusActive: {
    width: "6em",
    maxWidth: "6em",
    backgroundColor: "red",
    color: "white",
    borderRadius: 4
  },
  // result
  resultStartNumber: {
    display: "inline-block",
    fontSize: 38,
    fontWeight: 500,
    padding: "4px 16px 4px 16px",
    margin: "8px 32px 10px 32px",
    border: "2px solid #396593",
    color: "#396593",
    borderRadius: 4
  },
  resultName: {
    display: "inline-block",
    fontSize: 38,
    fontWeight: 500,
    margin: "10px 10px 10px 10px"
  },
  resultButtonList : {
    margin: "32px 0px 32px 16px",
  },
  resultButton: {
    ...genericButton,
    fontSize: 24,
    width: "5.5em",
    padding: "12px 0px",
    display: "inline-block",
    backgroundColor: "#396593",
    color: "white",
    marginTop: 16
  },
  inactiveResultButton: {
    ...genericButton,
    fontSize: 24,
    width: "5.5em",
    padding: "12px 0px",
    display: "inline-block",
    backgroundColor: "gray",
    color: "black",
  },
  resultCancelButton: {
    ...genericButton,
    display: "inline-block",
    backgroundColor: "#388e3c",
    color: "white",
    fontSize: 24,
    width: "5.5em",
    padding: "12px 0px",
  },
  reachUp: {
    fontSize: 24,
    fontWeight: 500,
    paddingRight: 12,
    paddingLeft: 12,
    padding: "12px 0px",
    display: "inline-block",
    color: "red",
  },

  // verify
  verifyResult: {
    display: "inline-block",
    fontSize: 38,
    fontWeight: 500,
    padding: "8px 16px 8px 16px",
    margin: "24px 32px 16px 32px",
    color: "#396593",
  },

  verifyCancelButton: {
    ...genericButton,
    display: "inline-block",
    backgroundColor: "#388e3c",
    color: "white",
    fontSize: 24,
    width: "5.5em",
    padding: "12px 0px",
    marginRight: 60
  },
  // state
  state : {
    minWidth: "8em",
    width: "8em",
    maxWidth: "8em"
  },

  '@media (max-width: 590px)': {
    routeListEntry: {
      marginLeft: 2,
      padding: 2,
      height: 32,
      maxHeight: 32,
      fontWeight: 500,
      fontSize: 14,
      whiteSpace: 'nowrap'
    },
    addName: {
      marginTop: 8,
      marginLeft: 8,
      fontSize: 24,
      fontWeight: 500,
      color: "teal"
    },
    simpleTries: {
      marginTop: 8,
      marginLeft: 8,
      marginBottom: 4,
      fontSize: 24,
      fontWeight: 500,
    },
    resultStartNumber: {
      display: "inline-block",
      fontSize: 24,
      fontWeight: 500,
      padding: "2px 2px 2px 2px",
      margin: "8px 32px 10px 32px",
      border: "2px solid #396593",
      color: "#396593",
      borderRadius: 4
    },
    resultName: {
      display: "inline-block",
      margin: "0px 4px 0px 4px",
      fontSize: 24,
      fontWeight: 500,
      color: "teal"
    },
    resultButtonList : {
      margin: "4px 0px 4px 4px",
    },
    resultButton: {
      ...genericButton,
      fontSize: 24,
      width: "5.5em",
      padding: "4px 0px",
      display: "inline-block",
      backgroundColor: "#396593",
      color: "white",
      marginTop: 16
    },
    resultCancelButton: {
      ...genericButton,
      display: "inline-block",
      backgroundColor: "#388e3c",
      color: "white",
      fontSize: 24,
      width: "5.5em",
      marginTop: "4px",
      padding: "4px 0px",
    },
    verifyCancelButton: {
      ...genericButton,
      display: "inline-block",
      backgroundColor: "#388e3c",
      color: "white",
      fontSize: 24,
      width: "5.5em",
      padding: "4px 0px"
    },
    // verify
    verifyResult: {
      display: "inline-block",
      fontSize: 38,
      fontWeight: 500,
      padding: "8px 16px 8px 16px",
      margin: "8px 32px 8px 32px",
      color: "#396593",
    },
  },
};

export const useStyles = createUseStyles(routeStyle);