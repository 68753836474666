import {useSections} from "../Api/referee";
import {assume} from "../Lib/session";
import React, {useContext, useState} from "react";
import baseFetch from "../Lib/baseFetch";
import {AppContext} from "../App";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#eee"
  },
  title: {
    fontWeight: 500,
    fontSize: 24,
    minHeight: 40,
    height: 40,
    maxHeight: 40,
    marginTop: 16,
    marginBottom: 8,
    textTransform: "uppercase",
    color: "#388e3c",
  },
  button: {
    borderRadius: 2,
    padding: "8px",
    margin: "32px 32px",
    width: "6em",
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontWeight: 500,
    backgroundColor: "#388e3c",
    color: "white"
  },
  input: {
    width: "6em",
    height: "2em",
    marginLeft: 20
  }
});

const loadSection = (appDispatch, sectionId) => {
  baseFetch('GET', "/referee/section/" + sectionId, undefined,
      response => {
        appDispatch({action: "section-data", data: response});
      },
      () => alert('Hiba, kérlek szólja a supportnak.')
  )
};

export default () => {
  const [state, setState] = useState({value: ''});

  const {loadingStatus, data: sections} = useSections();

  const {appDispatch} = useContext(AppContext);
  const classes = useStyles();

  if (loadingStatus) return "...betöltés...";

  const send = () => {
    assume(
        {principalName: "ref1", password: state.value},
        () => loadSection(appDispatch, sections[0].id), // TODO
        () => alert('Sikertelen belépés')
    )
  };

  return (
      <div className={classes.main}>
        <div className={classes.title}>Versenybírói alkalmazás</div>
        <div>
          <b>PIN</b>
          <input className={classes.input} value={state.value} type="password" name="pin" onChange={(e) => setState({value: e.target.value})}/>
        </div>
        <div className={classes.button} onClick={send}>OK</div>
      </div>
  )
};