import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../App";
import baseFetch from "../../Lib/baseFetch";
import {useStyles} from "./routeStyle";

const noone = {
  id: null,
  name: null,
  tries: null
};

export default ({route, index, setMode}) => {
  const classes = useStyles();

  const {appState, appDispatch} = useContext(AppContext);

  const sectionId = appState.section.id;
  const tryLimit = appState.section.tryLimit;

  const [value, setValue] = useState('');
  const [participation, setParticipation] = useState(noone);

  useEffect(() => {
    const p = appState.participations[parseInt(value)];

    if (p !== undefined) {
      setParticipation({
        id: p.id,
        name: p.name,
        startNumber: p.startNumber,
        tries: (p.tries[route.id] || 0)
      });
      return;
    }

    if (!value) {
      setParticipation(noone);
      return;
    }

    let aborted = false;
    setParticipation({id: null, name: '...betöltés...', tries: null});

    baseFetch(
        'GET',
        "/referee/section/" + sectionId + "/participation/" + value,
        undefined,
        response => {
          if (!aborted) appDispatch({action: 'add-participation', response})
        },
        () => {
          if (!aborted) setParticipation(noone)
        }
    );

    return () => {
      aborted = true;
    }

    // eslint-disable-next-line
  }, [sectionId, value]);

  const queue = () => {
    if (participation.id == null) {
      alert('Nincs versenyző kiválasztva!');
      return;
    }

    const route = appState.routes[index];

    if (route.queue.indexOf(participation.startNumber) !== -1) {
      alert('Ez a versenyző már várakozik ezen az úton.');
      return;
    }

    route.queue.push(participation.startNumber);

    appDispatch({action: 'route-data', index: index, data: route});

    setMode('normal');

    baseFetch(
        'PATCH',
        "/referee/queue/" + route.id,
        {
          route: route.id,
          startNumbers: route.queue
        }
    );
  };

  return (
      <div style={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-around"}}>
        <div>
          <div className={classes.addTitle}>Rajtszám:</div>
          <input
              className={classes.addInput}
              autoFocus
              value={value}
              type="number"
              name="startNumber"
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) queue()
              }}
          />
          <div className={classes.addName}>{participation.name}</div>
          <Tries tries={participation.tries} tryLimit={tryLimit}/>
        </div>
        <div>
          <div className={classes.button} onClick={queue}>rendben</div>
          <div className={classes.button} onClick={() => setMode('normal')}>mégse</div>
        </div>
      </div>
  );
};

const Tries = ({tries, tryLimit}) => {
  const classes = useStyles();

  if (tries == null) return null;

  if (tries >= tryLimit) {
    return <div className={classes.addTries} style={{color: "red"}}> nincs több próba</div>
  } else {
    return <div className={classes.addTries}>{tryLimit - tries} próba van még</div>
  }
};