import baseFetch from "./baseFetch";
import SessionData from "./SessionData";


export function assume(authenticationData, onSuccess, onError) {
  baseFetch('PATCH', '/session/assume-migration', authenticationData,
      data => {
        if (onSuccess) onSuccess(new SessionData(data))
      },
      onError
  );
}

export function get(onSuccess, onError) {
  baseFetch('GET', '/session', undefined,
      data => {
        if (onSuccess) onSuccess(new SessionData(data))
      },
      onError, "callback");
}

export function renounce(onSuccess, onError) {
  baseFetch('PATCH', '/session/renounce', undefined, onSuccess, onError);
}
