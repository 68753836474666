import React, {useContext, useState} from "react";
import {AppContext} from "../../App";
import {useStyles} from "./routeStyle";
import moment from "moment";

export default ({route, index, mode, setMode, selected}) => {
  const classes = useStyles();
  const {appDispatch} = useContext(AppContext);

  const start = () => {
    if (selected !== null && selected !== route.queue[0]) {
      alert('Válaszd ki a legfelső versenyzőt az indításhoz.');
      return;
    }

    route.progress = {
      startNumber: route.queue[0],
      startTime: moment()
    };

    appDispatch({
      action: 'route-data',
      index: index,
      data: Object.assign({}, route)
    });
  };

  const entry = () => {

    route.progress.endTime = moment();

    appDispatch({
      action: 'route-data',
      index: index,
      data: Object.assign({}, route)
    });

    setMode('entry');
  };

  const cancel = () => {
    route.progress = null;

    appDispatch({
      action: 'route-data',
      index: index,
      data: Object.assign({}, route)
    });
  };

  let buttons = null;

  switch (mode) {
    case 'participant':
      buttons = null;
      break;
    default:
      if (route.progress == null) {
        if (route.queue.length !== 0) {
          buttons = (
              <div>
                <div className={classes.button} onClick={start}>indít</div>
              </div>
          );
        }
      } else if (mode === 'normal') {
        buttons = (
            <div>
              <div className={classes.button} onClick={entry}>kész</div>
              <br/>
              <div className={classes.button} onClick={cancel}>mégse</div>
            </div>
        );
      }
  }

  return (
      <div className={classes.state}>
        <div className={classes.label}>
          {route.label}
          <div className={classes.color} style={{backgroundColor: route.color}}>&nbsp;</div>
        </div>
        {buttons}
      </div>
  )
};