import React, {useMemo, useReducer, useEffect, useState} from 'react';
import './App.css';
import {renounce} from "./Lib/session";
import Login from "./Views/Login";
import Main from "./Views/Main";
import baseFetch from "./Lib/baseFetch";

// TODO not 100% sure about this, think of a better solution
export const uploads = [  ]; // this is a static array, all uploads go here
let uploadCounter = 1;
let uploadCallback = undefined; // this is updated by App.render so upload will call the latest

const reducer = (state, action) => {
  switch (action.action) {
    case "start":
      return start(state, action);
    case "stop":
      return stop(state, action);
    case "section-data":
      return sectionData(state, action);
    case "route-data":
      return routeData(state, action);
    case "participant-data":
      return participantData(state, action);
    case "climb-upload":
      return climbUpload(state, action);
    default:
      alert('Hiba, kérlek szólj a supportnak.');
      console.log(state.toJSON());
      return state;
  }
};

const sectionData = (state, action) => {
  const participations = {};

  action.data.participations.forEach(p => {
    participations[p.startNumber] = p;
  });

  state = state.merge({section: action.data, participations: participations});

  localStorage.setItem("ref-started", JSON.stringify(false));
  localStorage.setItem("ref-section", JSON.stringify(action.data));
  localStorage.setItem("ref-route-0", JSON.stringify(null));
  localStorage.setItem("ref-route-1", JSON.stringify(null));
  localStorage.setItem("ref-route-2", JSON.stringify(null));
  localStorage.setItem("ref-route-3", JSON.stringify(null));

  return state;
};


const start = (state, action) => {

  state = state.set("started", true);
  if (action.routeCount < 4) state = state.setIn(['routes', 3], null);
  if (action.routeCount < 3) state = state.setIn(['routes', 2], null);
  if (action.routeCount < 2) state = state.setIn(['routes', 1], null);

  localStorage.setItem("ref-started", JSON.stringify(true));
  localStorage.setItem("ref-route-0", JSON.stringify(state.routes[0]));
  localStorage.setItem("ref-route-1", JSON.stringify(state.routes[1]));
  localStorage.setItem("ref-route-2", JSON.stringify(state.routes[2]));
  localStorage.setItem("ref-route-3", JSON.stringify(state.routes[3]));

  return state;
};

const stop = (state, action) => {
  localStorage.removeItem("ref-started");
  localStorage.removeItem("ref-section");
  localStorage.removeItem("ref-route-0");
  localStorage.removeItem("ref-route-1");
  localStorage.removeItem("ref-route-2");
  localStorage.removeItem("ref-route-3");
  window.location.reload();
};

const routeData = (state, action) => {
  const index = action.index;

  action.data.climbingType = state.getIn(['section', 'climbingType']);

  state = state.setIn(['routes', index], action.data);

  localStorage.setItem("ref-route-" + index, JSON.stringify(state.routes[index]));

  return state;
};

const participantData = (state, action) => {

  const participations = state.section.participations;
  participations.push(action.data);

  state = state.setIn(['section', 'participations'], participations);
  state = state.setIn(['participations', action.data.startNumber], action.data);

  // TODO this saves the whole state, probably unnecessary - used by on-demand fetch AND result verify
  localStorage.setItem("ref-section", JSON.stringify(state.section));

  return state;
};

const climbUpload = (state, action) => {
  const climb = action.data
  const json = JSON.stringify(climb);

  localStorage.setItem('ref-upload-' + climb.uuid, json); // this one is deleted after a successful upload
  localStorage.setItem('ref-climb-' + climb.uuid, json); // this one remains there

  uploads.push(climb);
  upload();

  return state;
};

export const AppContext = React.createContext({});

export default ({initialState}) => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const [queueLength, setQueueLength] = useState(0);
  uploadCallback = setQueueLength;

  const context = useMemo(() => ({appState: state, appDispatch: dispatch}), [state, dispatch]);

  useEffect(upload, []); // run it only once

  if (state == undefined) return null;

  return (
      <AppContext.Provider value={context}>
        <div className="App">
          {state.section == null ? <Login/> : <Main/>}
        </div>
      </AppContext.Provider>
  );
}

const upload = () => {

  // check local storage when the uploads array is empty, just in case

  if (uploads.length === 0) {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key.indexOf('ref-upload') !== 0) continue;
      uploads.push(JSON.parse(localStorage.getItem(key)));
    }
  }

  if (uploads.length === 0) return; // ok, there are no pending uploads

  const climb = uploads[0]; // take the first upload

  baseFetch(
      'POST',
      "/referee/climb",
      climb,
      () => {
        const first = uploads.shift();
        if (first.uuid === climb.uuid) {
          console.log('uploaded: ' + climb.uuid + " remaining: " + uploads.length);
          localStorage.removeItem("ref-upload-" + climb.uuid)
        } else {
          uploads.push(first); // this is unnecessary, but better to be safe than sorry
        }
        uploadCallback(uploadCounter++);
        if (uploads.length !== 0) setTimeout(upload, 0)
      },
      (error) => {
        if (error.status === 400) {
          alert('Kommunikációs hiba, szólj kérlek.')
        } else if (error.status === 410 || error.status === 409 || error.status === 417) {
          alert('Lejárt munkamanet, szólj kérlek.')
        } else if (error.status >= 500) {
          alert('Szerver hiba, szólj kérlek.')
        } else {
          setTimeout(upload, 10000)
        }
      }
  );
};