import React, {useContext, useState} from "react";
import {AppContext} from "../../App";
import {useStyles} from "./routeStyle";
import baseFetch from "../../Lib/baseFetch";


export default ({index, route, setMode, selected, setSelected}) => {
  const {appDispatch} = useContext(AppContext);
  const classes = useStyles();

  const [revision, setRevision] = useState(0);

  const update = (index, route) => {
    appDispatch({action: 'route-data', index: index, data: route});

    baseFetch(
        'PATCH',
        "/referee/queue/" + route.id,
        {
          route: route.id,
          startNumbers: route.queue
        }
    );
  };

  const remove = () => {
    if (selected == null) return;

    route.queue = route.queue.filter(e => e !== selected);

    update(index, route);
    setSelected(null);
  };

  const up = () => {
    const q = route.queue;

    const idx = q.indexOf(selected);
    if (idx === -1 || idx === 0) return;

    const other = q[idx - 1];
    q[idx - 1] = selected;
    q[idx] = other;

    update(index, route);
    setRevision(revision + 1); // to trigger render
  };

  const down = () => {
    const q = route.queue;

    const idx = q.indexOf(selected);
    if (idx === -1 || idx === q.length - 1) return;

    const other = q[idx + 1];
    q[idx + 1] = selected;
    q[idx] = other;

    update(index, route);
    setRevision(revision + 1); // to trigger render
  };

  return (
      <div style={{flexGrow: 1}}>
        <div className={classes.queueHeader}>
          <div className={classes.button} onClick={() => setMode('participant')}>hozzáad</div>
          <div className={classes.button} onClick={up}>fel</div>
          <div className={classes.button} onClick={down}>le</div>
          <div className={classes.button} onClick={remove}>kivesz</div>
        </div>
        <div className={classes.queueTableContainer}>
          <table className={classes.queueTable}>
            <tbody>
            {route.queue.map(startNumber =>
                <Entry
                    key={startNumber}
                    route={route}
                    index={index}
                    startNumber={startNumber}
                    selected={selected}
                    setSelected={setSelected}/>)
            }
            </tbody>
          </table>
        </div>
      </div>
  )
};

const Entry = ({route, startNumber, selected, setSelected}) => {
  const {appState} = useContext(AppContext);
  const classes = useStyles();

  const {name, tries} = appState.participations[startNumber];

  let status;
  let statusClass;

  if (route.progress != null && route.progress.startNumber === startNumber) {
    status = "MÁSZIK";
    statusClass = classes.queueStatusActive;
  } else {
    status = appState.section.tryLimit - (tries[route.id] || 0);
    statusClass = classes.queueStatus;
  }

  return (
      <tr className={startNumber === selected ? classes.selected : undefined}
          onClick={() => setSelected(startNumber)}>
        <td className={classes.queueStartNumber}>{startNumber}</td>
        <td className={classes.queueName}>{name}</td>
        <td className={statusClass}>{status}</td>
      </tr>
  )
};