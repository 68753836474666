import React, {useContext} from "react";
import {AppContext} from "../../App";
import baseFetch from "../../Lib/baseFetch";
import {useStyles} from "./routeStyle";

export default ({index}) => {
  const {appState, appDispatch} = useContext(AppContext);

  const classes = useStyles();

  const route = appState.routes[index];

  const select = (route) => {
    baseFetch('GET', "/referee/queue/" + route.id, undefined,
        response => {
          appDispatch({
            action: 'route-data',
            index: index,
            data: Object.assign({progress: null, queue: response}, route)
          });
        },
        () => {
          appDispatch({
            action: 'route-data',
            index: index,
            data: Object.assign({progress: null, queue: []}, route)
          });
        }
    )
  };

  return (
      <div className={classes.routeSelectorContainer}>
        {appState.section.routes.map(r =>
            <RouteSelectorEntry key={r.id} select={select} route={r} selected={route ? route.id === r.id : false}/>
        )}
      </div>
  )
};

const RouteSelectorEntry = ({select, route, selected}) => {
  const classes = useStyles();

  let entryClasses = classes.routeListEntry;
  if (selected) entryClasses += " " + classes.selected;

  return (
      <div className={entryClasses} onClick={() => select(route)}>
        {route.label}
        <div className={selected ? classes.colorSelected : classes.color} style={{backgroundColor: route.color}}>&nbsp;</div>
      </div>
  )
};