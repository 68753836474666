import React, {useContext, useState} from "react";
import {AppContext, uploads} from "../App";
import {createUseStyles} from 'react-jss';
import Route from "./Route/Route";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    height: "100%",
    backgroundColor: "#eee"
  },
  mainHeight: {
    minHeight: "100vh",
    width: "100vh",
    maxHeight: "100vh"
  },
  '@media (min-height: 856px)': {
    mainHeight: {
      minHeight: "856",
      width: "856",
      maxHeight: "856"
    },
  },
  mainWidth: {
    minWidth: "100vw",
    width: "100vw",
    maxWidth: "100vw"
  },
  '@media (min-width: 600px)': {
    mainWidth: {
      minWidth: 600,
      width: 600,
      maxWidth: 600,
    }
  },
  title: {
    fontWeight: 500,
    fontSize: 24,
    minHeight: 40,
    height: 40,
    maxHeight: 40,
    marginTop: 16,
    marginBottom: 8,
    textTransform: "uppercase",
    color: "#388e3c",
  },
  button: {
    borderRadius: 4,
    padding: "8px 24px 8px 24px",
    margin: "4px 8px 4px 8px",
    cursor: 'pointer',
    textTransform: 'lowercase',
    fontSize: 18,
    fontWeight: 500,
    color: "white",
    backgroundColor: "#388e3c",
    width: "6em"
  }
});

export default () => {
  const {appState, appDispatch} = useContext(AppContext);
  const classes = useStyles();

  return (
      <div className={classes.container}>
        <div className={classes.main + " " + classes.mainWidth + " " + classes.mainHeight}>
          {appState.started ? <StartedMain/> : <RouteSelectMain/>}
        </div>
      </div>
  );
}

const start = (appDispatch, routeCount) => {
  appDispatch({action: "start", routeCount: routeCount});
  window.scrollTo(0, 0)
};

const stop = (appDispatch) => {
  if (window.confirm("Biztosan ki szeretnél lépni?")) {
    appDispatch({action: "stop"});
  }
};

const RouteSelectMain = () => {
  const {appState, appDispatch} = useContext(AppContext);
  const classes = useStyles();

  const [routeCount, setRouteCount] = useState(2);

  return (
      <React.Fragment>
        <div className={classes.title}>Útválasztás</div>

        <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
          {[...Array(routeCount).keys()].map(index => <Route key={index} index={index}/>)}
        </div>

        <div>
          <div style={{display: "inline-block"}}>
            <div className={classes.button} onClick={() => start(appDispatch, routeCount)}>indítás</div>
            <div className={classes.button} onClick={() => stop(appDispatch)}>kilépés</div>
          </div>
          <div style={{display: "inline-block"}}>
            {routeCount >= 4 ? null :
                <div className={classes.button} onClick={() => setRouteCount(Math.min(4, routeCount + 1))}>több út</div>
            }
            {routeCount <= 1 ? null :
                <div className={classes.button} onClick={() => setRouteCount(Math.max(1, routeCount - 1))}>kevesebb út</div>
            }
          </div>
        </div>
      </React.Fragment>
  );
}

const StartedMain = () => {
  const {appState, appDispatch} = useContext(AppContext);
  const classes = useStyles();

  return (
      <React.Fragment>
        {[...Array(4).keys()].map(index => <Route key={index} index={index}/>)}

        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div className={classes.button} onClick={() => stop(appDispatch)}>kilépés</div>
          {uploads.length === 0
              ? <div style={{paddingRight: 20, color: "#388e3c", fontWeight: 500}}>minden mászás fel van töltve</div>
              : <div style={{paddingRight: 20, color: "orange", fontWeight: 500}}>{uploads.length} mászás vár feltöltésre</div>
          }
        </div>
      </React.Fragment>
  );
}