export default {
  button: {
    border: "1px solid darkblue",
    borderRadius: 2,
    padding: "2px 8px 2px 8px",
    margin: "8px 4px",
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontSize: 12,
  }
};